import { useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import { Button, Card, Form, Input, Select } from "antd";

import ADMIN_BID_USER_QUERY, {
  ADMIN_BID_USER_QUERY_KEY_GEN,
} from "@sellernote/_shared/src/queries/forwarding/ADMIN_BID_USER_QUERY";
import { RegisterAdminBidUserFormValue } from "@sellernote/_shared/src/types/forwarding/adminBidUser";
import Layout from "@sellernote/_shared-for-forwarding-admin/src/containers/Layout";
import withRequireAuth from "@sellernote/_shared-for-forwarding-admin/src/hocs/withRequireAuth";

const formItemLayout = {
  labelCol: {
    xs: {
      span: 10,
    },
    sm: {
      span: 3,
    },
  },
  wrapperCol: {
    xs: {
      span: 5,
    },
    sm: {
      span: 5,
    },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 3,
    },
  },
};

const UserCreate = () => {
  const queryClient = useQueryClient();

  const history = useHistory();

  const [form] = Form.useForm();

  const {
    mutate: registerUser,
    ResponseHandler: ResponseHandlerOfRegisterUser,
  } = ADMIN_BID_USER_QUERY.useRegisterUser({
    successModalInfo: {
      handleConfirmSuccess: (initQuery) => {
        initQuery();
        queryClient.invalidateQueries(ADMIN_BID_USER_QUERY_KEY_GEN.all());
        history.go(-1);
      },
      customizeMessage: () => ({
        title: "유저를 생성했습니다.",
      }),
    },
  });

  const handleRegisterUser = (values: RegisterAdminBidUserFormValue) => {
    registerUser({
      ...values,
      freightType: values.freightType,
      registerType: values.registerType,
      businessType: "person",
    });
  };

  return (
    <Layout breadcrumbs={["사용자 관리"]} title={"사용자 생성"}>
      <Card>
        <Form
          {...formItemLayout}
          form={form}
          name="register"
          onFinish={handleRegisterUser}
          initialValues={{
            residence: ["zhejiang", "hangzhou", "xihu"],
            prefix: "86",
          }}
          scrollToFirstError
        >
          <Form.Item
            name="country"
            label="서비스 종류"
            initialValue={"KR"}
            rules={[
              {
                required: true,
                message: "서비스 종류를 선택해주세요",
              },
            ]}
          >
            <Select
              style={{
                width: "100%",
              }}
              placeholder={"회사명을 입력해주세요."}
            >
              <Select.Option value="KR">쉽다</Select.Option>
              <Select.Option value="SG">브링굿즈</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item
            name="company"
            label="회사명"
            rules={[
              {
                required: true,
                message: "회사명을 입력해주세요",
              },
            ]}
          >
            <Input
              style={{
                width: "100%",
              }}
              placeholder={"회사명을 입력해주세요."}
            />
          </Form.Item>

          <Form.Item
            name="name"
            label="성함"
            rules={[
              {
                required: true,
                message: "이름을 입력해주세요",
              },
            ]}
          >
            <Input
              style={{
                width: "100%",
              }}
              placeholder={"이름을 입력해주세요."}
            />
          </Form.Item>

          <Form.Item
            name="email"
            label="E-mail"
            rules={[
              {
                type: "email",
                message: "이메일 형식이 아닙니다!",
              },
              {
                required: true,
                message: "이메일을 입력해주세요!",
              },
            ]}
          >
            <Input placeholder={"이메일을 입력하세요"} />
          </Form.Item>

          <Form.Item
            name="phone"
            label="전화번호"
            rules={[
              {
                required: true,
                message: "전화번호를 입력해주세요",
              },
            ]}
          >
            <Input
              style={{
                width: "100%",
              }}
              placeholder={"전화번호를 입력해주세요."}
            />
          </Form.Item>

          <Form.Item
            name="registerType"
            label="서비스 종류"
            initialValue={undefined}
          >
            <Select
              style={{
                width: "100%",
              }}
              placeholder={"회사명을 입력해주세요."}
            >
              <Select.Option value={undefined}>구분없음</Select.Option>
              <Select.Option value="externalChannel">외부채널</Select.Option>
              <Select.Option value="fclPromotion">
                F타사견적서-100불
              </Select.Option>
            </Select>
          </Form.Item>

          <Form.Item
            name="freightType"
            label="화물타입"
            initialValue={undefined}
          >
            <Select
              style={{
                width: "100%",
              }}
              placeholder={"화물타입을 입력해주세요."}
            >
              <Select.Option value={undefined}>구분 없음</Select.Option>
              <Select.Option value="FCL">FCL</Select.Option>
              <Select.Option value="oceanTicket">오션티켓</Select.Option>
              <Select.Option value="consolidation">특송</Select.Option>
              <Select.Option value="fulfillment">풀필먼트</Select.Option>
              <Select.Option value="export">수출</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item {...tailFormItemLayout}>
            <Button type="primary" htmlType="submit">
              Register
            </Button>
          </Form.Item>
        </Form>
      </Card>

      {ResponseHandlerOfRegisterUser}
    </Layout>
  );
};

export default withRequireAuth(UserCreate);
