import { useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Typography,
} from "@mui/material";

export default function ScheduleRegisterConfirmModalBody({
  onScheduleRegister,
}: {
  onScheduleRegister: (sendsEmail: boolean) => void;
}) {
  const [sendsEmail, setSendsEmail] = useState(true);

  return (
    <Box style={{ display: "flex", flexDirection: "column" }}>
      <Typography variant="h5" fontWeight={"bold"}>
        입력하신 스케줄을 저장합니다.
      </Typography>

      <FormControlLabel
        control={
          <Checkbox
            size="small"
            checked={sendsEmail}
            onChange={(e) => setSendsEmail(e.target.checked)}
          />
        }
        label="스케줄 안내 메일도 발송하시겠습니까?"
        labelPlacement="end"
        sx={{ mt: 1 }}
      />

      <Button
        variant="contained"
        onClick={() => onScheduleRegister(sendsEmail)}
        sx={{ mt: 3 }}
        size="small"
      >
        확인
      </Button>
    </Box>
  );
}
