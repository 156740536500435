import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  TextField,
  Typography,
} from "@mui/material";

import { CHANGE_SCHEDULE_REASON_OPTION_LIST } from "@sellernote/_shared/src/constants/forwarding/vesselSchedule";

import AutoRenewalRadio from "./AutoRenewalRadio";

export default function ScheduleChangeReasonModalBody({
  isScheduleModifiedByMarineTrafficOrLiner,
  keepsAutoRenewal,
  setKeepsAutoRenewal,
  sendsEmail,
  setSendsEmail,
  scheduleChangeReason,
  setScheduleChangeReason,
  opensModalForMail,
  onClick,
}: {
  isScheduleModifiedByMarineTrafficOrLiner: boolean;
  keepsAutoRenewal: boolean;
  setKeepsAutoRenewal: (value: boolean) => void;
  sendsEmail: boolean;
  setSendsEmail: (value: boolean) => void;
  scheduleChangeReason: string;
  setScheduleChangeReason: (value: string) => void;
  opensModalForMail: boolean;
  onClick: () => void;
}) {
  return (
    <>
      <Box style={{ display: "flex", flexDirection: "column" }}>
        <Typography variant="h5" fontWeight={"bold"}>
          스케줄 수정 사유를 입력해주세요.
        </Typography>

        <Autocomplete
          options={CHANGE_SCHEDULE_REASON_OPTION_LIST}
          renderInput={(params) => (
            <TextField
              {...params}
              label="사유"
              onChange={({ target }) => setScheduleChangeReason(target.value)}
            />
          )}
          value={scheduleChangeReason}
          style={{ marginTop: 24 }}
          onChange={(_, value) => setScheduleChangeReason(value || "")}
        />

        <Typography variant="overline">
          위 사유는 화주에게 발송되는 스케줄 변경 안내 이메일에 포함됩니다.
        </Typography>

        {isScheduleModifiedByMarineTrafficOrLiner && (
          <AutoRenewalRadio
            keepsAutoRenewal={keepsAutoRenewal}
            setKeepsAutoRenewal={setKeepsAutoRenewal}
          />
        )}

        {!opensModalForMail && (
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                checked={sendsEmail}
                onChange={(e) => setSendsEmail(e.target.checked)}
              />
            }
            label="스케줄 변경 안내 메일도 발송하시겠습니까?"
            labelPlacement="end"
            sx={{ mt: 1 }}
          />
        )}

        <Button variant="contained" onClick={onClick} sx={{ mt: 1 }}>
          확인
        </Button>
      </Box>
    </>
  );
}
